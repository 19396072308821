let scrollMixins = {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.scrollAnchorPoint();
    })
  },
  beforeRouteUpdate(to, from, next) {
    this.scrollAnchorPoint();
    next();
  },
  methods: {
    scrollAnchorPoint() {
      if(this.$route.hash){
        setTimeout(() => {
          var topView = document.getElementById('navBar').offsetHeight;
          var scrollView = document.getElementById(this.$route.hash.replace("#",''));
          document.documentElement.scrollTop = scrollView.offsetTop-topView;
        },0)
      }
    }
  }
  
}
 
export default scrollMixins
