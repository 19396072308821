<template>
  <div>
    <headers />
    <div class="cmpWmV">
      <div class="dIQKtV">
        <div class="dbBJMc krsfeL">
          <div class="kpldnK">数壤科技 工业元宇宙缔造者</div>
        </div>
        <div class="gPaqoi rktWm">
          <div class="gnQxjW krsfeL icon_1"><img class="chUclb"
              :src="require('@/assets/image/productoverview/icon_1.png')">
            <div class="bNwEKf krsfeL">
              <div class="dCKgDR">数壤懂你</div>
              <div class="fLFaEi">专业的制造业 企业数字化转型</div>
            </div>
          </div>
          <div class="gnQxjW krsfeL icon_2"><img class="chUclb"
              :src="require('@/assets/image/productoverview/icon_2.png')">
            <div class="bNwEKf krsfeL">
              <div class="dCKgDR">轻松快捷</div>
              <div class="fLFaEi">大数据SAAS平台</div>
            </div>
          </div>
          <div class="gnQxjW krsfeL icon_3"><img class="chUclb"
              :src="require('@/assets/image/productoverview/icon_4.png')">
            <div class="bNwEKf krsfeL">
              <div class="dCKgDR">数壤商城</div>
              <div class="fLFaEi">制造业工业品 专属商城</div>
            </div>
          </div>
          <div class="gnQxjW krsfeL icon_4"><img class="chUclb"
              :src="require('@/assets/image/productoverview/icon_3.png')">
            <div class="bNwEKf krsfeL">
              <div class="dCKgDR">数字孪生</div>
              <div class="fLFaEi">工业元宇宙基础</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="background:#fff">
    <div class="page">
      <div id="manage" class="content">
        <div class="introduce-title">
          <img :src="require('@/assets/image/introduce.png')" alt="">（管理型） 全方位的数字化管理
        </div>
        <div class="flex-content">
          <img class="videoImg" :src="require('@/assets/image/productoverview/content1.png')" alt="" />
          <div class="introduce-con">
            <div class="names">制造过程管理系统</div>
            <div class="wells">一天上线、两小时掌握、三方面满意</div>
            <p class="introduce">生产制造过程的全程管理、实时监测、工作辅助。以数字化形式实际帮助企业减负增效。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="page">
      <div class="content">
        <div class="flex-content">
          <div class="introduce-con">
            <div class="names">能效提升管理系统</div>
            <div class="wells">工序不减少，费用少一截</div>
            <p class="introduce">能源及能源费用的节约管理，通过专业分析帮助企业节省开支。</p>
          </div>
          <img class="videoImg" :src="require('@/assets/image/productoverview/content2.png')" alt="" />
        </div>
      </div>
    </div>
    <div class="page">
      <div class="content">
        <div class="flex-content">
          <img class="videoImg" :src="require('@/assets/image/productoverview/content3.png')" alt="" />
          <div class="introduce-con">
            <div class="names">订单管理工具</div>
            <div class="wells">如影随形，有效拓展</div>
            <p class="introduce">帮助客户清晰、明白、实时的管理，既保证客户的有效拓展，又保证订单的按时交付。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="page">
      <div class="content">
        <div class="flex-content">
          <div class="introduce-con">
            <div class="names">BOSS中心</div>
            <div class="wells">运筹帷幄之中，掌握工厂内外</div>
            <p class="introduce">协助BOSS了解企业内、外各项情况。包括企业总览、科技管家、政策资讯等内容。</p>
          </div>
          <img class="videoImg" :src="require('@/assets/image/productoverview/content4.png')" alt="" />
        </div>
      </div>
    </div>
    <div class="page">
      <div class="content">
        <div class="flex-content">
          <img class="videoImg" :src="require('@/assets/image/productoverview/content5.png')" alt="" />
          <div class="introduce-con">
            <div class="names">数壤宝镜</div>
            <p class="introduce">工业场景透明化智能大屏，专业多维度显示各项信息，可以显示生产、供应、仓储、能源、订单、研发等多项内容，滚动播放，清晰实时</p>
          </div>
        </div>
      </div>
    </div>
    <div class="page">
      <div class="content">
        <div class="flex-content">
          <div class="introduce-con">
            <div class="names">数壤管家</div>
            <p class="introduce">
              具备显示+操作功能，一方面实时进行工单、工艺流程等内容显示，另一方面可以进行实时操作进行上工、报工、识别、扫码等等各项内容，方便现场作业与操作。是具有工业现场人脸识别+人工智能工位一体式触摸大屏。</p>
          </div>
          <img class="videoImg" :src="require('@/assets/image/productoverview/content6.png')" alt="" />
        </div>
      </div>
    </div>
    <div class="page">
      <div class="content">
        <div class="flex-content">
          <img class="videoImg" :src="require('@/assets/image/productoverview/content7.png')" alt="" />
          <div class="introduce-con">
            <div class="names">仓储和供应链管理系统</div>
            <div class="wells">推陈出新，防范未然</div>
            <p class="introduce">仓储与供应链的相关管理，为客户的产品仓储大额止损，保驾护航。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="page">
      <div class="content">
        <div class="flex-content">
          <div class="introduce-con">
            <div class="names">研发管理系统</div>
            <div class="wells">由人及企，有效提升</div>
            <p class="introduce">具备显示+研发流程整体管理，将研发项目锁定于企业之内而非员工个人，帮助企业对研发进行各方面提升。</p>
          </div>
          <img class="videoImg" :src="require('@/assets/image/productoverview/content8.png')" alt="" />
        </div>
      </div>
    </div>
    <div class="page">
      <div id="market" class="content">
        <div class="introduce-title">
          <img :src="require('@/assets/image/introduce.png')" alt="">（营销型） 别具一格的数字化营销
        </div>
        <div class="flex-content">
          <img class="videoImg" :src="require('@/assets/image/productoverview/content9.png')" alt="" />
          <div class="introduce-con">
            <div class="names">数壤工业商城</div>
            <div class="wells">双向推进，多点销售</div>
            <p class="introduce">帮助企业开源获得客户，为企业做到拓展客户，增加订单，增加收入。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="page">
      <div class="content">
        <div class="flex-content">
          <div class="introduce-con">
            <div class="names">客户管理系统</div>
            <div class="wells">步步紧跟，有效拓展</div>
            <p class="introduce">客户的实时管理，为客户做好良好的开源准备与基础，提供每月10条精准客户信息。</p>
          </div>
          <img class="videoImg" :src="require('@/assets/image/productoverview/content10.png')" alt="" />
        </div>
      </div>
    </div>
    <div class="page">
      <div class="content">
        <div class="flex-content">
          <img class="videoImg" :src="require('@/assets/image/productoverview/content11.png')" alt="" />
          <div class="introduce-con">
            <div class="names">人才中心</div>
            <div class="wells">专项对口，专职培训</div>
            <p class="introduce">辅助专业人才招聘及培训，帮助企业解决用工难，专业用工更难的实际问题，每年5门培训课程。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="page">
      <div class="content">
        <div class="flex-content">
          <div class="introduce-con">
            <div class="names">跨境电商</div>
            <div class="wells">境外销售，开拓渠道</div>
            <p class="introduce">专业的跨境电商团队，为您跨境营销，畅快走出国门</p>
          </div>
          <img class="videoImg" :src="require('@/assets/image/productoverview/content12.png')" alt="" />
        </div>
      </div>
    </div>
    <div class="page">
      <div class="content">
        <div class="flex-content">
          <img class="videoImg" :src="require('@/assets/image/productoverview/content13.png')" alt="" />
          <div class="introduce-con">
            <div class="names">辅助销售</div>
            <div class="wells">实际营销，增加销售</div>
            <p class="introduce">院校合作，实习生销售，按订单收入计算。</p>
          </div>
        </div>
        <div id="maps" class="introduce-title">
          产业地图
        </div>
        <div class="flex-content">
          <div class="maps">
            <img class="videoImg" :src="require('@/assets/image/productoverview/content14.png')" alt="" />
            <div class="desc">为行业、产业、区域打造的专属产业地图，聚沙成塔的产业提升。有点及链，由链及面的全面区域提升。</div>
          </div>
          <div class="maps">
            <img class="videoImg" :src="require('@/assets/image/productoverview/content15.png')" alt="" />
            <div class="desc">针对行业专属打造，全面上下游打通提升。</div>
          </div>
        </div>
        <div id="twin" class="introduce-title">
          数字孪生
        </div>
        <div class="flex-content">
          <img class="videoImg" :src="require('@/assets/image/productoverview/content16.png')" alt="" />
          <div class="introduce-con">
            <p class="introduce">通过产品数字化双胞胎、生产工艺流程数字化双胞胎、设备数字化双胞胎，数字孪生完整真实地再现了整个企业。</p>
          </div>
        </div>
      </div>
    </div>
    </div>
    <footers />
  </div>
</template>
<script>
import headers from '@/components/head';
import footers from '@/components/foot'
import scrollMixins from "@/utils/scrollMixins";
import redirect from "@/utils/redirect";
import pcRem from "@/utils/pcrem";
export default {
  mixins: [scrollMixins,redirect,pcRem],
  components: {
    headers,
    footers
  },  
  mounted() {
    
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
//1920*1080为设计稿，头部字体大小为25px
$ratio: 1920 / 76.8; // 缩放比   25

// 返回对应屏幕下的rem值
@function px2rem($px) {
  @return $px / $ratio +rem;
}

.cmpWmV {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  color: white;
  font-weight: 300;
  width: 100%;
  // background: url('~@/assets/image/productoverview/web-cover.png') rgb(31, 39, 61) no-repeat;
  // -webkit-background-size: cover;
  // -moz-background-size: cover;
  // background-size: cover;
  // background-attachment: fixed;
  // -webkit-background-attachment: fixed;
  // -moz-background-attachment: fixed;
  &::before{
    content: ' ';
    position: fixed;
    height: 100vh;
    width: 100%;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url('~@/assets/image/productoverview/web-cover.png') rgb(31, 39, 61) no-repeat;
    background-size: cover;
  }

  .dIQKtV {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    color: white;
    font-weight: 300;
    background-size: cover;
    background-attachment: fixed;
    width: px2rem(1260);
    background-color: transparent;
    // height: 6rem;
    -webkit-box-pack: justify;
    justify-content: space-between;

    .dbBJMc {
      -webkit-box-align: center;
      align-items: center;

      .kpldnK {
        font-size: px2rem(34);
        font-weight: 300;
        text-align: center;
      }
    }

    .gPaqoi {
      width: 100%;
      -webkit-box-align: center;
      align-items: center;
      justify-content: space-around;
      margin-top: px2rem(38);
    }

    .rktWm {
      display: flex;
      flex-direction: row;
    }

    .gnQxjW {
      width: px2rem(190);
      height: 100%;
      text-align: center;
      -webkit-box-align: center;
      align-items: center;
      justify-content: space-around;

      //菜鸡设计切图大小不一致，还带背景色，需要多个宽高
      &.icon_1 {
        img {
          width: px2rem(71);
          height: px2rem(76);
        }
      }

      &.icon_2 {
        img {
          width: px2rem(80);
          height: px2rem(80);
        }
      }

      &.icon_3 {
        img {
          width: px2rem(81);
          height: px2rem(66);
        }
      }

      &.icon_4 {
        img {
          width: px2rem(72);
          height: px2rem(65);
        }
      }

      .bNwEKf {
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;

        .dCKgDR {
          font-size: px2rem(28);
          margin-top: px2rem(15);
          font-weight: 300;
        }

        .fLFaEi {
          font-size: px2rem(24);
          font-weight: 300;
          margin-top: px2rem(10);
        }
      }
    }

    .krsfeL {
      display: flex;
      flex-direction: column;
    }
  }
}

.page {
  width: 100%;
  padding: px2rem(30) 0;

  &:nth-child(even) {
    background: #F5F5F5;
  }
}

.content {
  margin: auto;
  width: px2rem(1210);
}

.introduce-title {
  position: relative;
  margin: px2rem(79) 0 px2rem(47);
  padding-left: px2rem(25);
  height: px2rem(45);
  font-size: px2rem(32);
  font-weight: 500;

  img {
    width: px2rem(158);
    height: px2rem(47);
  }

  &::before {
    position: absolute;
    top: px2rem(1);
    left: 0;
    content: '';
    width: px2rem(5);
    height: px2rem(45);
    background: #308971;
    border-radius: px2rem(3);
  }
}

.flex-content {
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: px2rem(761);
    height: px2rem(340);
  }

  .introduce-con {
    width: px2rem(403);

    .names {
      margin-bottom: px2rem(20);
      font-size: px2rem(26);
      font-weight: bold;
    }

    .wells {
      display: inline-block;
      margin-bottom: px2rem(30);
      padding: 0 px2rem(36);
      background: #308971;
      border-radius: px2rem(32);
      height: px2rem(40);
      line-height: px2rem(40);
      font-size: px2rem(18);
      color: #FFFFFF;
    }

    .introduce {
      font-size: px2rem(22);
      line-height: px2rem(30);
    }
  }

  .maps {
    width: px2rem(587);

    img {
      display: block;
      width: px2rem(587);
      height: px2rem(280);
    }

    .desc {
      padding: px2rem(23);
      display: flex;
      align-items: center;
      width: px2rem(585);
      height: px2rem(105);
      border: 1px solid #E4E4E4;
      border-top: 0;
      border-radius: 0 0 px2rem(10) px2rem(10);
      box-sizing: border-box;
      font-size: px2rem(22);
      line-height: px2rem(30);
    }
  }
}
</style>
